














import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/app'
import { Stripe, loadStripe } from '@stripe/stripe-js';
const taxRates = ['txr_1ILnsNEBmDLHDo57indbM7zh'];

import firebase from 'firebase/app';
import 'firebase/firestore';
import fs = firebase.firestore;
const db = firebase.firestore();
const functions = firebase.app().functions('asia-northeast2');

import BaseButton from '@/components/BaseButton.vue'
import Panel from '@/components/Panel.vue'
@Component({
  components: {
    BaseButton,
    Panel
  },
})
export default class OperatorPayment extends Vue {
  private product = {} as any;
  private price = {} as any;
  private subscription = {} as any;
  private stripe: Stripe | null = null;
  private role: (string | null) = null;

  async mounted() {
    firebase.auth().onAuthStateChanged(user => {
      AppModule.setCurrentUser(user);
      if (user) {
        this.getProducts();
        this.getCustomer();
        this.getCustomClaimRole();
      }else{
        this.$router.push('/manage/signin');
      }
    });
    this.stripe = await loadStripe('pk_test_51IH7r4EBmDLHDo57hCGED7jTgw89SpUZJ3nk06MShJ6HjRtnbJdVkpEJtAlQUnQWVYvYQbISRaiNvPHehaE1Az0T00hK6JMGx5');
  }

  private async getProducts() {
    db.collection('products').where('active', '==', true).get().then((querySnapshot)=>{
      querySnapshot.forEach(async  (doc: any) => {
        this.product = doc.data();
        this.product.name = this.product.name.toUpperCase();
        this.product.description = this.product.description?.toUpperCase() || '';

        const priceSnap = await doc.ref.collection('prices').orderBy('unit_amount').get();
        priceSnap.docs.forEach((doc: any) => {
          this.price = {
            id: doc.id,
            description: `毎月${new Intl.NumberFormat('ja-JP', {style: 'currency',currency: doc.data().currency,}).format(doc.data().unit_amount)}`
          }
        });
      });
    });
  }

  private getCustomer() {
    db.collection('customers').doc(AppModule.currentUser?.uid).collection('subscriptions').where('status', 'in', ['trialing', 'active']).onSnapshot(async (snapshot) => {
      if (!snapshot.empty) {
        const {status, stripeLink} = snapshot.docs[0].data()
        this.subscription = { status: status, stripeLink: stripeLink };
      }
    });
  }

  private isTransacting = false;
  private async subscribe(event: any) {
    this.isTransacting = true;

    const docRef = await db.collection('customers').doc(AppModule.currentUser?.uid).collection('checkout_sessions')
      .add({
        price: this.price.id,
        'allow_promotion_codes': true,
        'tax_rates': taxRates,
        'success_url': `${window.location.origin}/manage/payment`,
        'cancel_url': `${window.location.origin}/manage/payment`,
        metadata: {},
      });
    docRef.onSnapshot((snap) => {
      const { error, sessionId } = snap.data() as any;
      if (error) {
        alert(`An error occured: ${error.message}`);
        this.isTransacting = false;
      }
      if (sessionId) {
        this.stripe!.redirectToCheckout({ sessionId });
      }
    });

    this.getCustomer();
  }

  private async getCustomClaimRole() {
    await firebase.auth().currentUser!.getIdToken(true);
    const decodedToken = await firebase.auth().currentUser!.getIdTokenResult();
    this.role = decodedToken.claims.stripeRole;
  }
}
